import React, { Suspense, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  NavLink,
  useHistory,
} from "react-router-dom";
import { ReactQueryDevtools } from "react-query-devtools";
import ErrorBoundary from "./components/ErrorBoundary";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { AppBar } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CenteredContent from "./components/CenteredContent";
import "./localization.js";
import "./App.scss";
import Topbar from "./components/Topbar";
import auth, { SessionContext } from "./auth";

function AuthenticatedRoute(props) {
  const { component: Component, acceptedRoles, ...rest } = props;
  const currentUser = auth.currentUser();
  return (
    <ErrorBoundary>
      <Route
        {...rest}
        render={(props) => {
          if (auth.isAuthenticated()) {
            if (!acceptedRoles || acceptedRoles.includes(currentUser.role)) {
              return <Component {...props} rest={rest} currentUser={currentUser} />;
            } else {
              // TODO: Improve
              return <div>Not found</div>;
            }
          } else {
            return <Redirect to={{ pathname: "/" }} />;
          }
        }}
      />
    </ErrorBoundary>
  );
}

function UnauthenticatedRoute({ component: Component, ...rest }) {
  if (auth.isAuthenticated()) {
    return <Redirect to={{ pathname: "/", state: { referrer: rest.location.pathname } }} />;
  } else {
    return (
      <ErrorBoundary>
        <Route {...rest} render={(props) => <Component {...props} />} />
      </ErrorBoundary>
    );
  }
}

const DashBoard = React.lazy(() => import("./views/DashBoard"));
const Profile = React.lazy(() => import("./views/Profile"));
const SingleAthlete = React.lazy(() => import("./views/SingleAthlete"));
const Login = React.lazy(() => import("./views/Login"));
const ResetPassword = React.lazy(() => import("./views/ResetPassword"));
const Admin = React.lazy(() => import("./views/Admin"));
const Register = React.lazy(() => import("./views/Register"));
const VideoAdmin = React.lazy(() => import("./views/VideoAdmin"));
const TemplatesAdmin = React.lazy(() => import("./views/TemplatesAdmin"));

const PrivacyStatement = React.lazy(() => import("./views/PrivacyStatement"));

const theme = createMuiTheme({
  palette: {
    // used to represent primary interface elements for a user.
    primary: {
      main: "rgb(0, 29, 67)",
    },
    // - used to represent secondary interface elements for a user.
    secondary: {
      main: "rgb(0, 48, 87)",
    },
    // error - used to represent interface elements that the user should be made aware of.
    // warning - used to represent potentially dangerous actions or important messages.
    // info - used to present information to the user that is neutral and not necessarily important.
    // success - used to indicate the successful completion of an action that user triggered.
  },
});

const defaultPathsForRoles = {
  admin: "/athletes",
  coach: "/athletes",
  athlete: "/athlete/training_plan",
};

export default function App() {
  const history = useHistory();
  const [sessionValidated, setSessionValidated] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const accessToken = auth.accessToken();
  const [toggleLoggedIn, setToggleLoggedIn] = useState(false);

  useEffect(() => {
    auth.validateSession().then(() => {
      setSessionValidated(true);
    });
  }, []);

  useEffect(() => {
    const user = auth.currentUser();
    if (!currentUser) {
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
    }
    if (!toggleLoggedIn) {
      setCurrentUser(null);
    }
    if (user) {
      setCurrentUser(user);
    }
  }, [toggleLoggedIn, currentUser, sessionValidated]);

  const signOut = () => {
    setToggleLoggedIn(false);
    setCurrentUser(null);
    auth.deleteSession();
    history.push("/login");
  };

  return (
    <SessionContext.Provider value={{ currentUser, accessToken }}>
      <ThemeProvider theme={theme}>
        <Router>
          {!sessionValidated ? (
            <>
              <AppBar elevation={0} position="static">
                {/*<Topbar
                  user={currentUser}
                  setDrawer={setDrawer}
                  setToggleLoggedIn={setToggleLoggedIn}
                  setSelectedSeason={setSelectedSeason}
                />*/}
              </AppBar>
              <div style={{ width: "100%" }}>
                <CenteredContent>
                  <CircularProgress size={50} />
                </CenteredContent>
              </div>
              <Footer />
            </>
          ) : (
            <div className="app-container">
              <div style={{ width: "100%" }}>
                <AppBar elevation={0}>
                  <Topbar
                    user={currentUser}
                    currentUser={currentUser}
                    setCurrentUser={setCurrentUser}
                    setToggleLoggedIn={setToggleLoggedIn}
                  />
                </AppBar>
              </div>
              <div style={{ flex: 1, display: "flex", paddingTop: 63 }}>
                <Suspense
                  fallback={
                    <div style={{ width: "100%" }}>
                      <CenteredContent>
                        <CircularProgress size={50} />
                      </CenteredContent>
                    </div>
                  }
                >
                  <Switch>
                    <Redirect exact from="/register/new-athlete?token=token" to={"/register"} />

                    <Redirect
                      exact
                      from="/"
                      to={{
                        pathname: defaultPathsForRoles[currentUser?.role] || "/login",
                      }}
                    />

                    <Redirect from="/auth/password/edit" to="/reset_password" />

                    <UnauthenticatedRoute
                      path="/login"
                      component={() => {
                        return (
                          <>
                            <Login setToggleLoggedIn={setToggleLoggedIn} />
                            <Footer />
                          </>
                        );
                      }}
                    />
                    <UnauthenticatedRoute
                      path="/reset_password"
                      component={() => <ResetPassword />}
                    />
                    <Route path="/privacy_statement" component={PrivacyStatement} />
                    <UnauthenticatedRoute path="/register" component={Register} />

                    <AuthenticatedRoute
                      acceptedRoles={["admin", "coach"]}
                      exact
                      path="/athletes"
                      signOut={signOut}
                      component={DashBoard}
                    />

                    <AuthenticatedRoute
                      acceptedRoles={["admin", "coach"]}
                      path="/athletes/:id"
                      component={SingleAthlete}
                    />

                    <AuthenticatedRoute
                      acceptedRoles={["admin", "coach"]}
                      path="/groups/:id"
                      component={SingleAthlete}
                    />
                    <AuthenticatedRoute
                      acceptedRoles={["admin", "coach"]}
                      exact
                      path="/admin"
                      currentUser={currentUser}
                      component={Admin}
                    />

                    <AuthenticatedRoute
                      acceptedRoles={["admin"]}
                      path="/video_admin"
                      currentUser={currentUser}
                      component={VideoAdmin}
                    />

                    <AuthenticatedRoute
                      acceptedRoles={["admin"]}
                      path="/templates"
                      component={TemplatesAdmin}
                    />

                    <AuthenticatedRoute
                      acceptedRoles={["athlete"]}
                      path="/athlete"
                      component={SingleAthlete}
                    />
                    <AuthenticatedRoute
                      acceptedRoles={["athlete"]}
                      path="/authenticateStrava"
                      component={(props) => {
                        //consume.log("loc", props);
                        if (
                          props.location &&
                          props.location.state &&
                          props.location.state.redirect_url
                        ) {
                          window.location = props.location.state.redirect_url;
                          return null;
                        } else {
                          return (
                            <Redirect
                              exact
                              from="/authenticateStrava"
                              to={"/athlete/athlete_info?syncSuccess=true"}
                            />
                          );
                        }
                      }}
                    />

                    <AuthenticatedRoute path="/profile" component={Profile} />
                    <Route component={NoMatch} />
                  </Switch>
                </Suspense>
              </div>
            </div>
          )}
        </Router>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </SessionContext.Provider>
  );
}

const Footer = () => {
  return (
    <div
      style={{
        backgroundColor: "#4B6F99",
        position: "absolute",
        bottom: 0,
        paddingTop: 10,
        paddingBottom: 10,
        width: "100vw",
      }}
    >
      <NavLink
        to={"/privacy_statement"}
        style={{ marginLeft: 10, color: "white", textDecoration: "none" }}
      >
        Electrofit tietosuojaseloste
      </NavLink>
    </div>
  );
};

const NoMatch = () => {
  return (
    <h3 style={{ textAlign: "center", marginTop: "20%", color: "#0D2F54" }}>
      404: Hakemaasi sivua ei löytynyt
    </h3>
  );
};
