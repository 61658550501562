import React from "react";
import styles from "./Button.module.scss";
import classNames from "classnames";
import {
  Button as Btn,
  IconButton
} from '@material-ui/core';
import PlusIcon from "mdi-react/PlusIcon";

const Button = ({
  children,
  outlined,
  small,
  medium,
  large,
  plusIconButton,
  onClick
}) => {

  const buttonClassNames = classNames(styles.button, {
    [styles.red]: false,
  });

  if(plusIconButton){
    return(

      <IconButton aria-label="delete" onClick={onClick}variant="outlined">
          <PlusIcon />
      </IconButton>
    )
  } else {
    return(
      <Btn
        onClick={onClick}
        variant={outlined ? "outlined" : "contained"}
        color="primary"
        size={small || medium || large}
        >
          {children}
        </Btn>
    )
  }

}
export default Button
