import React, { useContext } from "react";
import Cookies from "js-cookie";
import wretch from "wretch";

import honeybadger from "./honeybadger";

const getValidateSession = async (body) => {
  try {
    const paramsString = Object.keys(body)
      .reduce((params, key) => {
        return params.concat(`${key}=${body[key]}`);
      }, [])
      .join("&");

    const headers = auth.session() || {};
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    headers["X-CSRF-Token"] = csrfToken;

    return await wretch(`/auth/validate_token?${paramsString}`).headers(headers).get().json();
  } catch (error) {
    return {
      success: false,
    };
  }
};

const auth = {
  sessionValidated: false,

  isAuthenticated() {
    return this.sessionValidated;
  },

  async validateSession() {
    if (this.session()) {
      const session = await getValidateSession(this.session());
      if (session.success) {
        this.sessionValidated = true;
        honeybadger.setContext({
          user_id: session.data.id,
          user_email: session.data.email,
        });
        this._currentUser = session.data;
      } else {
        this.deleteSession();
      }
    }
  },

  currentUser() {
    return this._currentUser;
  },

  setSession({ session, currentUser }) {
    this.sessionValidated = true;
    this._currentUser = currentUser;

    honeybadger.setContext({
      user_id: currentUser.id,
      user_email: currentUser.email,
    });
    Cookies.set("session", JSON.stringify(session), { expires: 365 });
  },

  accessToken() {
    const session = this.session();
    return session && session["access-token"];
  },

  getHeaders() {
    const sessionData = Cookies.get("session");
    return sessionData;
  },

  session() {
    if (this._session) {
      return this._session;
    }
    try {
      const sessionData = Cookies.get("session");
      if (sessionData) {
        this._session = JSON.parse(sessionData);
      } else {
        this._session = null;
      }
    } catch (error) {
      honeybadger.notify(error);
      this._session = null;
    }
    return this._session;
  },

  deleteSession() {
    this._currentUser = null;
    this._session = null;
    this.sessionValidated = false;
    Cookies.remove("session");
  },
};

export default auth;

export const SessionContext = React.createContext({});

export function useSession() {
  return useContext(SessionContext);
}
