import React from "react";
import { useState, useEffect } from "react";

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResizeEvent = () => {
      if (!isMobile && window.innerWidth < 800) {
        setIsMobile(true);
      } else if (isMobile && window.innerWidth >= 800) {
        setIsMobile(false);
      }
    };

    window.addEventListener("resize", handleResizeEvent);

    return function cleanup() {
      window.removeEventListener("resize", handleResizeEvent);
    };
  }, [isMobile]);
  return isMobile;
};
