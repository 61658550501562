import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import myIcon from "../../assets/images/logo.svg";
import AddAthlete from "./AddAthlete";
import GeneralModal from "./GeneralModal";
import DrawerNavigation from "./DrawerNavigation";

import LanguageSwitcher from "./LanguageSwitcher";
import styles from "./Topbar.module.css";
import { useTranslation } from "react-i18next";
import SeasonSelector from "./SeasonSelector";
import auth, { useSession } from "../auth";
import MenuIcon from "mdi-react/MenuIcon";
import { useIsMobile } from "../helpers/useIsMobile";

export default function Topbar(props) {
  const {
    user,
    currentUser,
    setCurrentUser,
    setToggleLoggedIn,
    selectedSeason,
    setSelectedSeason,
  } = props;
  const [open, setOpen] = useState(false);
  const [drawer, setDrawer] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const mobile = useIsMobile();
  const { t } = useTranslation();

  const signOut = () => {
    setOpen(false);
    setToggleLoggedIn(false);
    setCurrentUser(null);
    auth.deleteSession();
    history.push("/login");
  };

  const RoleBoundary = ({ children, currentUser }) => {
    const { role } = currentUser;
    if (role === "admin") {
      return children;
    } else {
      return null;
    }
  };

  const onClick = () => {
    setDrawer(true);
  };

  return (
    <>
      <Toolbar style={{ height: 64 }}>
        <div style={{ flexGrow: 1 }}>
          <NavLink
            to={user && user.role !== "athlete" ? "/athletes" : "/"}
            style={{ textDecoration: "none", flex: 1 }}
          >
            <img
              src={myIcon}
              alt="icon"
              className={styles.sparkLogo}
              height={"40px"}
              style={{ flexGrow: 1, position: "relative", top: 5 }}
            />
          </NavLink>
          {!mobile && (
            <SeasonSelector
              selectedSeason={selectedSeason}
              setSelectedSeason={props.setSelectedSeason}
              desktop
              user={user}
            />
          )}
        </div>

        {user && (
          <>
            <Button onClick={() => setDrawer(true)}>
              <MenuIcon color="white" />
            </Button>
          </>
        )}
      </Toolbar>
      <GeneralModal modalOpen={modalOpen} setModalOpen={setModalOpen}>
        <AddAthlete />
      </GeneralModal>
      <DrawerNavigation
        drawer={drawer}
        setDrawer={setDrawer}
        currentUser={currentUser}
        signOut={signOut}
      />
    </>
  );
}
