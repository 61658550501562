import i18n from "i18next";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { FormGroup, Switch } from "@material-ui/core";
import styles from "./LanguageSwitcher.module.css";

const LanguageSwitcher = ({ black }) => {
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const lang = Cookies.get("lang");

  ////consume.log("lang", lang);
  const [checked, setChecked] = useState(lang === "fi" ? false : true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      Cookies.set("lang", "en", { expires: 365 });
      changeLanguage("en");
    } else {
      Cookies.set("lang", "fi", { expires: 365 });
      changeLanguage("fi");
    }
  };

  return (
    <FormGroup row>
      <p className={styles.langText} style={{ color: black ? "black" : "white" }}>
        fi
      </p>
      <div style={{ marginTop: 8 }}>
        <Switch
          checked={checked}
          onChange={handleChange}
          color="default"
          inputProps={{ "aria-label": "checkbox with default color" }}
        />
      </div>
      <p className={styles.langText} style={{ color: black ? "black" : "white" }}>
        en
      </p>
    </FormGroup>
  );
};
export default LanguageSwitcher;
