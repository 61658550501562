import { Drawer, List, ListItem, Divider, ListItemText } from "@material-ui/core";
import React from "react";
import { useState, useEffect } from "react";
import auth, { useSession } from "../auth";
import { useIsMobile } from "../helpers/useIsMobile";
import { useSeasons } from "../helpers/useSeasons";
import { getUser, getGroup } from "../queries/queries";
import { useTranslation } from "react-i18next";
import { Switch, Route, useHistory, useLocation, Redirect } from "react-router-dom";
import { useQuery } from "react-query";

const DrawerNavigation = ({ match, drawer, setDrawer, currentUser, signOut }) => {
  const isMobile = useIsMobile();
  const history = useHistory();
  const query = new URLSearchParams(window.location.search);

  const { pathname } = useLocation();
  const athleteId = currentUser?.role == "athlete" ? currentUser.id : pathname.split("/")[2];
  const groupId = pathname.split("/")[2];
  const groupRoute = pathname.includes("groups");
  const seasonId = query.get("s");

  const { selectedSeason, seasonOptions, setSeasons } = useSeasons(
    currentUser?.role === "athlete" ? currentUser.id : null,
    true
  );

  ////console.log("selectedSeason", selectedSeason);

  useEffect(() => {
    if (athleteId || groupId || currentUser?.role === "athlete") {
      setSeasons(athleteId || groupId || currentUser.id);
    }
  }, [athleteId, groupId, currentUser, seasonId]);

  const {
    data: user,
    refetch: refetchSingleAthlete,
    error,
  } = useQuery(["user", athleteId], getUser, {
    staleTime: 10000,
    enabled: athleteId && isMobile && !groupRoute,
    refetchOnWindowFocus: false,
  });

  const { data: group, refetch: refetchGroup } = useQuery(["group", groupId], getGroup, {
    refetchOnWindowFocus: false,
    enabled: groupRoute && isMobile && currentUser?.role !== "athlete",
  });

  const changeSeason = (event, id) => {
    let val = event?.target?.value || id;
    let selectedOption = seasonOptions.find((season) => season.id == val);
    let query = new URLSearchParams(window.location.search);

    query.set("s", selectedOption.id);
    let search = query.toString();

    setDrawer(false);
    setTimeout(() => {
      history.push({
        pathname: history.location.pathname,
        search: search,
      });
    }, 300);
  };

  const setSelectedSeason = () => {};

  const { t } = useTranslation();

  const handleChange = (path) => {
    setDrawer(false);

    //consume.log("path", path);
    setTimeout(() => {
      if (athleteId && !pathname.includes("groups")) {
        history.push({
          pathname:
            currentUser.role === "athlete"
              ? "/athlete/" + path
              : "/athletes/" + user.id + "/" + path,
          search: history.location.search,
        });
      }
      if (groupId && pathname.includes("groups")) {
        //consume.log("groupId", groupId);

        history.push({
          pathname: "/groups/" + group.id + "/" + path,
          search: history.location.search,
        });
      }
      if (currentUser.role === "athlete") {
        //consume.log("lol wat");
        history.push({
          pathname: "/athlete/" + path,
          search: history.location.search,
        });
      }
    }, 300);
  };

  const isActive = (page) => {
    return window.location.pathname.includes(page);
  };

  const hasSelectedTarget = athleteId || groupId;

  return (
    <>
      {currentUser?.role !== "athlete" ? (
        <Drawer anchor={"right"} open={drawer} onClose={() => setDrawer(false)}>
          {isMobile && (
            <>
              {" "}
              <List style={{ paddingTop: 0, paddingBottom: 0 }}>
                <ListItem
                  style={{
                    height: 64,
                  }}
                >
                  <ListItemText
                    style={{
                      fontStyle: "italic",
                    }}
                    inset={false}
                    primary={currentUser?.name}
                  />
                </ListItem>
                <Divider />
                {hasSelectedTarget && (
                  <ListItem
                    style={{
                      backgroundColor: "#4B6F99",
                    }}
                  >
                    <ListItemText
                      inset={false}
                      style={{
                        color: "white",

                        fontStyle: "italic",
                      }}
                      primary={group?.name || user?.name}
                    />
                  </ListItem>
                )}

                {hasSelectedTarget && (
                  <>
                    <ListItem
                      button
                      onClick={() => {
                        handleChange(!groupRoute ? "training_plan" : "training_plan_group");
                      }}
                    >
                      <ListItemText
                        inset={false}
                        primary={t("SingleAthlete.WeeklyTraining")}
                        style={{
                          color: isActive("training_plan") ? "#4B6F99" : "#404040",
                        }}
                      />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        handleChange(!groupRoute ? "seasonal_plan" : "seasonal_plan_group");
                      }}
                    >
                      <ListItemText
                        inset={false}
                        style={{
                          color: isActive("seasonal_plan") ? "#4B6F99" : "#404040",
                        }}
                        primary={t("SingleAthlete.AnnualTraining")}
                      />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        handleChange(!groupRoute ? "strength" : "strength_group");
                      }}
                    >
                      <ListItemText
                        inset={false}
                        style={{
                          color: isActive("strength") ? "#4B6F99" : "#404040",
                        }}
                        primary={t("SingleAthlete.Strength")}
                      />
                    </ListItem>
                    {user?.swimming_training && (
                      <ListItem
                        button
                        onClick={() => {
                          handleChange(!groupRoute ? "swimming" : "swimming_group");
                        }}
                      >
                        <ListItemText
                          inset={false}
                          style={{
                            color: isActive("swimming") ? "#4B6F99" : "#404040",
                          }}
                          primary={t("SingleAthlete.Swimming")}
                        />
                      </ListItem>
                    )}
                    {user?.running_training && (
                      <ListItem
                        button
                        onClick={() => {
                          handleChange(!groupRoute ? "running" : "running_group");
                        }}
                      >
                        <ListItemText
                          inset={false}
                          style={{
                            color: isActive("running") ? "#4B6F99" : "#404040",
                          }}
                          primary={t("SingleAthlete.Running")}
                        />
                      </ListItem>
                    )}

                    <ListItem
                      button
                      onClick={() => {
                        handleChange("athlete_info");
                      }}
                    >
                      <ListItemText
                        inset={false}
                        style={{
                          color: isActive("athlete_info") ? "#4B6F99" : "#404040",
                        }}
                        primary={t("SingleAthlete.PersonalInfo")}
                      />
                    </ListItem>
                  </>
                )}
              </List>
              <Divider />
            </>
          )}

          {!isMobile && (
            <>
              <ListItem
                style={{
                  backgroundColor: "#4B6F99",
                  height: 64,
                }}
              >
                <ListItemText
                  inset={false}
                  style={{ color: "white" }}
                  primary={currentUser?.name}
                />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  setDrawer(false);
                  history.push("/athletes");
                }}
              >
                <ListItemText inset={false} primary={"Urheilijat"} />
              </ListItem>
              {currentUser?.role === "admin" && !isMobile && (
                <>
                  <ListItem
                    button
                    onClick={() => {
                      setDrawer(false);
                      history.push("/video_admin");
                    }}
                  >
                    <ListItemText inset={false} primary={"Hallinnoi videoita"} />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      setDrawer(false);
                      history.push("/admin");
                    }}
                  >
                    <ListItemText inset={false} primary={"Hallinnoi käyttäjiä"} />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      setDrawer(false);
                      history.push("/templates");
                    }}
                  >
                    <ListItemText inset={false} primary={"Hallinnoi pohjia"} />
                  </ListItem>
                  <Divider />
                </>
              )}
            </>
          )}
          {isMobile &&
            seasonOptions?.map((season) => (
              <>
                <ListItem
                  key={season.id}
                  button
                  onClick={() => {
                    changeSeason(null, season.id);
                  }}
                >
                  <ListItemText
                    inset={false}
                    style={{
                      color:
                        season?.id == seasonId || (selectedSeason?.id === season.id && !seasonId)
                          ? "#4B6F99"
                          : "#404040",
                    }}
                    primary={t("SingleAthlete.Season") + " " + season.season}
                  />
                </ListItem>
              </>
            ))}
          <Divider />
          <ListItem
            button
            onClick={() => {
              setDrawer(false);
              signOut();
            }}
          >
            <ListItemText inset={false} primary={"Kirjaudu ulos"} />
          </ListItem>
        </Drawer>
      ) : (
        <Drawer anchor={"right"} open={drawer} onClose={() => setDrawer(false)}>
          {isMobile && (
            <>
              {" "}
              <List style={{ paddingTop: 0, paddingBottom: 0 }}>
                <ListItem
                  style={{
                    height: 64,
                    backgroundColor: "#4B6F99",
                  }}
                >
                  <ListItemText
                    style={{
                      color: "white",
                      fontStyle: "italic",
                    }}
                    inset={false}
                    primary={currentUser?.name}
                  />
                </ListItem>

                <ListItem
                  button
                  onClick={() => {
                    handleChange(
                      user || currentUser.role === "athlete"
                        ? "training_plan"
                        : "training_plan_group"
                    );
                  }}
                >
                  <ListItemText
                    inset={false}
                    primary={t("SingleAthlete.WeeklyTraining")}
                    style={{
                      color: isActive("training_plan") ? "#4B6F99" : "#404040",
                    }}
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    handleChange(
                      user || currentUser.role === "athlete"
                        ? "seasonal_plan"
                        : "seasonal_plan_group"
                    );
                  }}
                >
                  <ListItemText
                    inset={false}
                    style={{
                      color: isActive("seasonal_plan") ? "#4B6F99" : "#404040",
                    }}
                    primary={t("SingleAthlete.AnnualTraining")}
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    handleChange("strength");
                  }}
                >
                  <ListItemText
                    inset={false}
                    style={{
                      color: isActive("strength") ? "#4B6F99" : "#404040",
                    }}
                    primary={t("SingleAthlete.Strength")}
                  />
                </ListItem>
                {currentUser?.swimming_training && (
                  <ListItem
                    button
                    onClick={() => {
                      handleChange("swimming");
                    }}
                  >
                    <ListItemText
                      inset={false}
                      style={{
                        color: isActive("swimming") ? "#4B6F99" : "#404040",
                      }}
                      primary={t("SingleAthlete.Swimming")}
                    />
                  </ListItem>
                )}
                {currentUser?.running_training && (
                  <ListItem
                    button
                    onClick={() => {
                      handleChange("running");
                    }}
                  >
                    <ListItemText
                      inset={false}
                      style={{
                        color: isActive("running") ? "#4B6F99" : "#404040",
                      }}
                      primary={t("SingleAthlete.Running")}
                    />
                  </ListItem>
                )}
                <ListItem
                  button
                  onClick={() => {
                    handleChange("athlete_info");
                  }}
                >
                  <ListItemText
                    inset={false}
                    style={{
                      color: isActive("athlete_info") ? "#4B6F99" : "#404040",
                    }}
                    primary={t("SingleAthlete.PersonalInfo")}
                  />
                </ListItem>
              </List>
              <Divider />
            </>
          )}

          {!isMobile && (
            <>
              <ListItem
                style={{
                  backgroundColor: "#4B6F99",
                  height: 64,
                }}
              >
                <ListItemText
                  inset={false}
                  style={{ color: "white" }}
                  primary={currentUser?.name}
                />
              </ListItem>
            </>
          )}
          {isMobile &&
            seasonOptions?.map((season) => (
              <>
                <ListItem
                  key={season.id}
                  button
                  onClick={() => {
                    changeSeason(null, season.id);
                  }}
                >
                  <ListItemText
                    inset={false}
                    style={{
                      color: season?.id == seasonId ? "#4B6F99" : "#404040",
                    }}
                    primary={t("SingleAthlete.Season") + " " + season.season}
                  />
                </ListItem>
              </>
            ))}
          {isMobile && !currentUser?.role === "athlete" && (
            <ListItem
              button
              onClick={() => {
                handleChange("athlete_info");
              }}
            >
              <ListItemText
                inset={false}
                style={{
                  color: isActive("athlete_info") ? "#4B6F99" : "#404040",
                }}
                primary={t("SingleAthlete.PersonalInfo")}
              />
            </ListItem>
          )}
          <Divider />
          <ListItem
            button
            onClick={() => {
              setDrawer(false);
              signOut();
            }}
          >
            <ListItemText inset={false} primary={"Kirjaudu ulos"} />
          </ListItem>
        </Drawer>
      )}
    </>
  );
};

export default DrawerNavigation;
