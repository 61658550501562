import React from "react";
import HoneyBadgerErrorBoundary from "@honeybadger-io/react";
import honeybadger from "../honeybadger";

const chunkFailedMessage = /Loading chunk [\d]+ failed|Loading CSS chunk [\d]+ failed/;

function ErrorComponent({ error }) {
  //consume.log("error", error);
  if (error && chunkFailedMessage.test(error.message)) {
    return (
      <div className="error">
        <div>Tapahtui virhe</div>
        <div>{this.error}</div>
        <div>{this.info}</div>
        <button
          onClick={() => {
            window.location.reload();
          }}
        >
          Lataa uudelleen
        </button>
      </div>
    );
  } else {
    return (
      <div className="error">
        <div style={{ textAlign: "center", color: "orange" }}>
          <h3>Tapahtui virhe!</h3>
        </div>
      </div>
    );
  }
}

const ErrorBoundary = ({ children }) => {
  return (
    <HoneyBadgerErrorBoundary honeybadger={honeybadger} ErrorComponent={ErrorComponent}>
      {children}
    </HoneyBadgerErrorBoundary>
  );
};

export default ErrorBoundary;
