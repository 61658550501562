import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./localizations/en.json";
import fi from "./localizations/fi.json";
import Cookies from "js-cookie";

////consume.log("asdasdasd!!!", Cookies.get("lang"));
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: en,
      },
      fi: {
        translation: fi,
      },
    },
    lng: Cookies.get("lang"),
    fallbackLng: "fi",
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
