import wretch from "wretch";

import auth from "../auth";

const HOST = process.env.API_HOST;

const getHeaders = () => {
  const headers = auth.session() || {};
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  headers["X-CSRF-Token"] = csrfToken;
  return headers;
};

export const getUsers = async () => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/users`).headers(headers).get().json();
};

// users
export const getUser = async (key, id) => {
  ////consume.log(key, id);
  const headers = getHeaders();
  return await wretch(`${HOST}/api/users/${id}`).headers(headers).get().json();
};

export const updateUser = async ({ id, attributes }) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/users/${id}`).headers(headers).post(attributes).json();
};

export const patchUser = async ({ id, attributes }) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/users/${id}`).headers(headers).patch(attributes).json();
};

export const deleteUser = async (id) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/users/${id}`).headers(headers).delete({ id: id });
};

export const addUser = async (attributes) => {
  return await wretch(`${HOST}/auth/invitation`).patch(attributes).json();
};

export const inviteAthlete = async (attributes) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/users`).headers(headers).post(attributes).json();
};

export const forgotPassword = async ({ attributes }) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/auth/password`).headers(headers).post(attributes).json();
};

export const patchPassword = async ({ attributes, headers }) => {
  return await wretch(`${HOST}/auth/password`).headers(headers).patch(attributes).json();
};

// strava
export const getStravaData = async () => {
  const headers = getHeaders();
  const stravaHeaders = {
    ...headers,
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  return await wretch(`${HOST}/strava`).headers(stravaHeaders).get().json();
};

export const getStravaConnectionStatus = async (key, user) => {
  const headers = getHeaders();
  const stravaHeaders = {
    ...headers,
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  return await wretch(`${HOST}/strava/status?user_id=${user.id}`)
    .headers(stravaHeaders)
    .get()
    .json();
};

export const disconnectStrava = async (id) => {
  const headers = getHeaders();
  const stravaHeaders = {
    ...headers,
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  return await wretch(`${HOST}/strava?user_id=${id}`).headers(stravaHeaders).delete().json();
};

//strength

export const getStrengths = async (key, { user_id }) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/power_exercises`)
    .headers(headers)
    .query({ user_id: user_id })
    .get()
    .json();
};

export const addStrength = async (attributes) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/power_exercises`).headers(headers).post(attributes).json();
};

export const addTemplateExercise = async ({ attributes, id }) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/template_psr_programs/` + id)
    .headers(headers)
    .put(attributes)
    .json();
};

export const deleteTemplateRow = async ({ programId, excerciseId }) => {
  //consume.log(programId, excerciseId);
  const headers = getHeaders();
  return await wretch(`${HOST}/api/template_psr_programs/${programId}`)
    .headers(headers)
    .put({
      template_psr_exercises_attributes: [
        {
          id: excerciseId,
          _destroy: true,
        },
      ],
    });
};

export const deleteStrength = async ({ excerciseId }) => {
  const headers = getHeaders();
  //consume.log("excerciseId", excerciseId);
  return await wretch(`${HOST}/api/power_exercises/${excerciseId}`)
    .headers(headers)
    .delete({ id: excerciseId });
};

export const getGroupStrengths = async (key, { group_id }) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/group_power_exercises`)
    .headers(headers)
    .query({ group_id: group_id })
    .get()
    .json();
};

export const addGroupStrength = async (attributes) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/group_power_exercises`).headers(headers).post(attributes).json();
};

export const deleteGroupStrength = async ({ programId }) => {
  const headers = getHeaders();
  //consume.log("excerciseId", programId);

  return await wretch(`${HOST}/api/group_power_exercises/${programId}`)
    .headers(headers)
    .delete({ id: programId });
};

//swimming

export const getSwimmingDrills = async (key, { user_id }) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/swimming_exercises`)
    .headers(headers)
    .query({ user_id: user_id })
    .get()
    .json();
};

export const addSwimming = async (attributes) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/swimming_exercises`).headers(headers).post(attributes).json();
};

export const deleteSwimming = async ({ excerciseId }) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/swimming_exercises/${excerciseId}`)
    .headers(headers)
    .delete({ id: excerciseId });
};

export const getGroupSwimming = async (key, { group_id }) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/group_swimming_exercises`)
    .headers(headers)
    .query({ group_id: group_id })
    .get()
    .json();
};

export const addGroupSwimming = async (attributes) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/group_swimming_exercises`)
    .headers(headers)
    .post(attributes)
    .json();
};

export const deleteGroupSwimming = async ({ programId }) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/group_swimming_exercises/${programId}`)
    .headers(headers)
    .delete({ id: programId });
};

//running

export const getRunning = async (key, { user_id }) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/running_exercises`)
    .headers(headers)
    .query({ user_id: user_id })
    .get()
    .json();
};

export const addRunning = async (attributes) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/running_exercises`).headers(headers).post(attributes).json();
};

export const deleteRunning = async ({ excerciseId }) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/running_exercises/${excerciseId}`)
    .headers(headers)
    .delete({ id: excerciseId });
};

export const deleteFile = async (params) => {
  const { fileId, userId } = params;
  const headers = getHeaders();
  return await wretch(`${HOST}/api/users/${userId}/uploaded_files/${fileId}`)
    .headers(headers)
    .delete();
};

export const getGroupRunning = async (key, { group_id }) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/group_running_exercises`)
    .headers(headers)
    .query({ group_id: group_id })
    .get()
    .json();
};

export const addGroupRunning = async (attributes) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/group_running_exercises`)
    .headers(headers)
    .post(attributes)
    .json();
};

export const deleteGroupRunning = async ({ excerciseId }) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/group_running_exercises/${excerciseId}`)
    .headers(headers)
    .delete({ id: excerciseId });
};

//templates

export const getTemplate = async (key, params) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/${params.templateType}?template_id=${params.templateId}`)
    .headers(headers)
    .get()
    .json();
};

export const deleteTemplate = async (params) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/templates/${params.templateId}`).headers(headers).delete();
};

export const getTemplates = async (key, params) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/templates?template_type=${params.templateType}`)
    .headers(headers)
    .get()
    .json();
};

export const createNewTemplate = async (attributes) => {
  const headers = getHeaders();
  //consume.log("attributes", attributes);
  // //consume.log("attributes", attributes);
  return await wretch(`${HOST}/api/${"templates" /*attributes.type*/}`)
    .headers(headers)
    .post(attributes.payload)
    .json();
};

export const createNewPowerTemplate = async (payload) => {
  const headers = getHeaders();
  // //consume.log("attributes", attributes);
  //consume.log("payload here", payload);
  return await wretch(`${HOST}/api/template_psr_programs`).headers(headers).post(payload).json();
};

//annual

export const getSeasons = async ({ id, owner_type }) => {
  const headers = getHeaders();
  ////consume.log({ id, owner_type });
  return await wretch(`${HOST}/api/seasons?owner_id=${id}&owner_type=${owner_type}`)
    .headers(headers)
    .get()
    .json();
};

export const getAnnualTraining = async (key, { user_id, season_id }) => {
  const headers = getHeaders();

  return await wretch(`${HOST}/api/year_programs`)
    .headers(headers)
    .query({ user_id: user_id, season_id: season_id })
    .get()
    .json();
};

export const getPieData = async (key, { user_id, season_id }) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/charts`)
    .headers(headers)
    .query({ user_id: user_id, season_id: season_id })
    .get()
    .json();
};

export const updateTemplateProgram = async ({ id, templateType, payload }) => {
  const headers = getHeaders();

  //consume.log("id, templateType, payload", id, templateType, payload);
  //consume.log("ere");

  return await wretch(`${HOST}/api/${templateType}/${id}`).headers(headers).put(payload).json();
};

export const updateTemplateData = async ({ id, payload }) => {
  const headers = getHeaders();

  return await wretch(`${HOST}/api/templates/${id}`).headers(headers).put(payload).json();
};

export const updateMonthTheme = async ({ id, attributes }) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/periods/${id}`).headers(headers).put(attributes).json();
};

export const updateTemplateMonthTheme = async ({ id, attributes }) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/template_periods/${id}`).headers(headers).put(attributes).json();
};

export const updateWeek = async ({ id, attributes }) => {
  const headers = getHeaders();

  return await wretch(`${HOST}/api/year_programs/${id}`).headers(headers).put(attributes).json();
};

export const updateTemplateWeek = async ({ id, attributes }) => {
  const headers = getHeaders();

  return await wretch(`${HOST}/api/template_year_programs/${id}`)
    .headers(headers)
    .put(attributes)
    .json();
};

export const updateGroupWeek = async ({ id, attributes }) => {
  const headers = getHeaders();

  return await wretch(`${HOST}/api/group_year_programs/${id}`)
    .headers(headers)
    .put(attributes)
    .json();
};

export const updateCreateAnnualTraining = async (attributes) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/year_programs`).headers(headers).post(attributes).json();
};

export const getGroupAnnualTraining = async (key, { group_id, season_id }) => {
  const headers = getHeaders();

  return await wretch(`${HOST}/api/group_year_programs`)
    .headers(headers)
    .query({ group_id: group_id, season_id: season_id })
    .get()
    .json();
};

export const updateCreateGroupAnnualTraining = async (attributes) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/group_year_programs`).headers(headers).post(attributes).json();
};

//weeklyPlan

export const getWeeklyTraining = async (key, { season_id, page }) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/training_programs?season_id=${season_id}&page=${page}`)
    .headers(headers)

    .get()
    .json();
};

export const createWeeklyTrainingDay = async (attributes) => {
  const headers = getHeaders();
  ////consume.log();
  return await wretch(`${HOST}/api/training_programs`).headers(headers).post(attributes).json();
};

export const createWeeklyGroupTrainingDay = async (attributes) => {
  const headers = getHeaders();
  ////consume.log();
  return await wretch(`${HOST}/api/group_training_programs`)
    .headers(headers)
    .post(attributes)
    .json();
};

export const updateWeeklyTrainingDay = async ({ id, slug, attributes }) => {
  const headers = getHeaders();

  return await wretch(`${HOST}/api/${slug}/${id}`).headers(headers).put(attributes).json();
};

export const updateGroupWeeklyTrainingDay = async ({ id, attributes }) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/group_training_programs/${id}`)
    .headers(headers)
    .put(attributes)
    .json();
};

export const deleteTraining = async (id) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/training_programs/${id}`).headers(headers).delete();
};

export const getWeeklyGroupTraining = async (key, { season_id, page }) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/group_training_programs?season_id=${season_id}&page=${page}`)
    .headers(headers)

    .get()
    .json();
};

export const updateCreateWeeklyGroupTraining = async (attributes) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/group_training_programs`)
    .headers(headers)
    .post(attributes)
    .json();
};

export const deleteGroupTraining = async (group_id) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/group_training_programs/${group_id}`).headers(headers).delete();
};

//videos

export const getVideos = async () => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/videos`).headers(headers).get().json();
};

export const getVideo = async (key, id) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/videos/${id}`).headers(headers).get().json();
};

export const pushVideo = async ({ attributes }) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/videos`).headers(headers).post(attributes).json();
};

export const deleteVideo = async (id) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/videos/${id}`).headers(headers).delete({ id: id });
};

//groups

export const getGroups = async () => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/groups`).headers(headers).get().json();
};

export const getGroup = async (key, id) => {
  ////consume.log("getgroup", key, id);
  if (!id) {
    return;
  }
  const headers = getHeaders();
  return await wretch(`${HOST}/api/groups/${id}`).headers(headers).get().json();
};

export const createGroup = async ({ attributes }) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/groups`).headers(headers).post(attributes).json();
};

export const patchGroup = async ({ id, attributes }) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/groups/${id}`).headers(headers).patch(attributes).json();
};

export const deleteGroup = async (id) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/groups/${id}`).headers(headers).delete({ id: id });
};

export const addAthleteToGroup = async (attributes) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/user_groups`).headers(headers).post(attributes).json();
};

export const deleteAthleteFromGroup = async (attributes) => {
  const { user_id, group_id } = attributes;
  const headers = getHeaders();
  return await wretch(`${HOST}/api/user_groups?user_id=${user_id}&group_id=${group_id}`)
    .headers(headers)
    .delete()
    .json();
};

//multiple coaches

export const putAthleteUnderCoach = async (attributes) => {
  const headers = getHeaders();
  return await wretch(`${HOST}/api/coach_athletes`).headers(headers).post(attributes).json();
};

export const removeAthleteFromUnderCoach = async (attributes) => {
  const { coach_id, athlete_id } = attributes;
  const headers = getHeaders();
  return await wretch(`${HOST}/api/coach_athletes?athlete_id=${athlete_id}&coach_id=${coach_id}`)
    .headers(headers)
    .delete(attributes);
};
