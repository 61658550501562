import i18n from "i18next";
import React, { useState } from "react";
import dayjs from "dayjs";
import { useSeasons } from "../helpers/useSeasons";
import styles from "./SeasonSelector.module.css";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

const useParams = () => new URLSearchParams(window.location.search);

var isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween);

const SeasonSelector = ({ setSelectedSeason, desktop, user, mobile, init }) => {
  const [selected, setSelected] = useState(null);
  const [show, setShow] = useState(false);
  const history = useHistory();

  const { seasonOptions, selectedSeason, setSeasons } = useSeasons(
    user?.role === "athlete" ? user?.id : null,
    true
  );

  let query = new URLSearchParams(window.location.search);

  const seasonParam = query.get("s");

  const handleChange = (event) => {
    let val = event.target.value;
    let selectedOption = seasonOptions.find((season) => season.id == val);

    query.set("s", selectedOption.id);
    let search = query.toString();

    setSelected(selectedOption);
    setTimeout(() => {
      history.push({
        search: search,
      });
    }, 300);
  };

  useEffect(() => {
    if (selectedSeason && seasonOptions) {
      setSelected(selectedSeason);
    }
  }, [selectedSeason]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const seasonId = query.get("s");
    if (seasonId && !selectedSeason) {
      setSeasons(null, seasonId);
    }
  }, [window.location.search]);

  return (
    <div className={desktop ? styles.selectorContainer : styles.mobileContainer}>
      {seasonOptions && (
        <select
          onChange={(e) => handleChange(e)}
          value={selected?.id}
          className={desktop ? styles.select : styles.selectMobile}
        >
          {seasonOptions?.map((season) => (
            <option key={season.season} value={season.id} style={{ fontSize: 16 }}>
              Kausi {season.season}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

export default SeasonSelector;
