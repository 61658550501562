import Honeybadger from "honeybadger-js";

const config = {
  apiKey: "7f9faa0c",
  environment: process.env.NODE_ENV,
  disabled: false,
  reportData: true,
  breadcrumbsEnabled: true,
};

const honeybadger = Honeybadger.configure(config);

export default honeybadger;
