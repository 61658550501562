import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { getUser, getGroup, getSeasons } from "../queries/queries";
import { Switch, Route, useHistory, useLocation, Redirect } from "react-router-dom";
import dayjs from "dayjs";

const getSeasonOptions = (seasonData) => {
  if (seasonData?.length) {
    let seasons = seasonData?.map((season) => {
      return {
        season:
          dayjs(season.start_date).format("YYYY") +
          "-" +
          dayjs(season.start_date).add(1, "year").format("YYYY"),
        year: dayjs(season.start_date).format("YYYY"),
        start_date: season.start_date,
        end_date: season.end_date,
        id: season.id,
      };
    });

    seasons = seasons?.map((season) => {
      let hasDuplicate = seasons.some(
        (item) =>
          item.id !== season.id &&
          dayjs(item.start_date).format("YYYY") === dayjs(season.start_date).format("YYYY")
      );
      if (hasDuplicate) {
        season.season =
          dayjs(season.start_date).format("YYYY") +
          "-" +
          dayjs(season.start_date).add(1, "year").format("YYYY") +
          " (" +
          dayjs(season.start_date).format("D.MM") +
          ")";
      }
      return season;
    });

    seasons = seasons
      ?.sort((a, b) => {
        return new Date(b.start_date) - new Date(a.start_date);
      })
      .reverse();

    return seasons;
  }
};

const testDate = null; //new Date("2024-04-07");

const getInitialPage = (selectedSeason, passedDate) => {
  if (!selectedSeason) {
    return 1;
  }

  const startDate = dayjs(selectedSeason.start_date);
  const endDate = dayjs(selectedSeason.end_date);
  const currentDate = dayjs(testDate || new Date());

  // Calculate the total number of days in the season, add one to include the start date
  const seasonDurationInDays = endDate.diff(startDate, "day") + 1;

  // Define the number of days in a standard 4-week period
  const daysInStandardPeriod = 4 * 7;

  // Create an array to hold the periods
  const periods = [];

  // Calculate the number of 4-week periods in the season
  const fullPeriods = seasonDurationInDays / daysInStandardPeriod;

  // Calculate the remaining days after full periods
  const remainingDays = seasonDurationInDays % daysInStandardPeriod;

  // Create full periods
  for (let i = 0; i < fullPeriods; i++) {
    const periodStartDate = startDate.add(i * daysInStandardPeriod, "day");
    const periodEndDate = periodStartDate.add(daysInStandardPeriod - 1, "day");

    periods.push({ start: periodStartDate, end: periodEndDate });
  }

  // Create the last period if there are remaining days
  if (remainingDays > 0) {
    const lastPeriodStartDate = endDate.subtract(remainingDays - 1, "day");
    periods.push({ start: lastPeriodStartDate, end: endDate });
  }

  // Calculate the current period

  const currentPeriod =
    periods.findIndex((period) => {
      const start = new Date(period.start);
      const end = new Date(period.end);
      const current = new Date(currentDate);

      const isStartOrIsEnd =
        dayjs(current).isSame(start, "day") || dayjs(current).isSame(end, "day");

      return (dayjs(current).isAfter(start) && dayjs(current).isBefore(end)) || isStartOrIsEnd;
    }) + 1;

  const initialPage = Math.min(Math.max(currentPeriod, 1), periods.length);

  ////console.log("currentPeriod", currentPeriod);
  // Ensure the current period is within a valid range (1 to the total number of periods)
  return { initialPage: initialPage, periodsAmount: periods?.length };
};

export const useSeasons = (currentUserId, disabled, selectedUser, selectedGroup) => {
  const [loading, setLoading] = useState(true);
  const [seasonOptions, setSeasonOptions] = useState(null);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [initialPage, setInitialPage] = useState(null);
  const { pathname } = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(window.location.search);

  const setupSeasons = async (id, owner_type, seasonId) => {
    setLoading(true);
    let data = await getSeasons({ id: id, owner_type: owner_type });

    if (data && data.length === 0) {
      setLoading(false);
    } else {
      let seasonOptions = getSeasonOptions(data);

      // ////console.log("seasonOptions", seasonOptions);

      function findCurrentSeason(dateToCheck) {
        let current = seasonOptions?.find(
          (season) =>
            dayjs(testDate ? testDate : dateToCheck).isBetween(
              season?.start_date,
              season?.end_date,
              null,
              "[]"
            ) || // Include seasons that start or end on the given date
            dayjs(season?.start_date).isSame(dateToCheck, "day") ||
            dayjs(season?.end_date).isSame(dateToCheck, "day")
        );
        return current;
      }

      const currentDate = testDate ? dayjs(testDate) : new Date(); //"2024-11-11"; // Example date 1

      let current = findCurrentSeason(currentDate);
      //const today = testDate ? dayjs(new Date(testDate)) : dayjs();
      const noOngoingSeason = !current && seasonOptions.length > 0; //testDate ? current : seasonOptions.length > 0 && !current;
      let seasonIsInTheFuture;
      if (noOngoingSeason) {
        current = seasonOptions[seasonOptions.length - 1];

        seasonIsInTheFuture = dayjs(current?.start_date).isAfter(currentDate);
      }

      //if there is no current ongoing season, show the most recent one if it exists
      let selected = current
        ? current
        : seasonOptions.length - 2
        ? seasonOptions[seasonOptions.length - 2]
        : seasonOptions[0];

      if (seasonId && parseInt(seasonId) !== current.id) {
        selected = seasonOptions.find((season) => season.id == seasonId);
      }

      let { initialPage, periodsAmount } = getInitialPage(
        selected,
        current ? null : selected?.end_date
      );

      if (noOngoingSeason) {
        console.log("athlete has no ongoing season");
        if (seasonIsInTheFuture) {
          console.log("next season is in the future");
          initialPage = 1;
        } else {
          console.log("last season was in the past");
          initialPage = periodsAmount;
        }
      }

      //set which page should load initially to the weekly training view, set season options for selecting a season
      setInitialPage(initialPage);
      setSeasonOptions(seasonOptions);
      if (!selected && seasonOptions.length > 0) {
        setSelectedSeason(seasonOptions[0]);
      } else {
        setSelectedSeason(selected);
      }

      setLoading(false);
    }
  };

  const setSeasons = (passedId, seasonId) => {
    const athleteId = pathname.split("/")[2] || passedId;
    const groupId = pathname.split("/")[2];

    if (athleteId) {
      setupSeasons(athleteId, "User", seasonId);
    }

    if (groupId) {
      setupSeasons(groupId, "Group", seasonId);
    }
  };

  useEffect(() => {
    const seasonId = query.get("s");
    const searchId = pathname.split("/")[2];
    const groupMode = pathname.includes("groups");

    if (!searchId && seasonOptions) {
      setSeasonOptions(null);
      setSelectedSeason(null);
      setInitialPage(null);
    }

    if (!seasonOptions && searchId) {
      setupSeasons(searchId, groupMode ? "Group" : "User", seasonId);
    }

    if (!seasonOptions && currentUserId) {
      setupSeasons(currentUserId, "User", seasonId);
    }
  }, [currentUserId, selectedUser, selectedGroup, pathname]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const seasonId = query.get("s");

    if (seasonOptions && selectedSeason && selectedSeason?.id !== seasonId) {
      setLoading(true);
      const newSelected = seasonOptions.find((o) => o.id == seasonId);

      const { initialPage, periodsAmount } = getInitialPage(newSelected);
      ////console.log("use", initialPage);
      setSelectedSeason(newSelected);
      setInitialPage(initialPage);
      setLoading(false);
    }
    if (!seasonId && seasonOptions) {
      setSeasonOptions(null);
      setSelectedSeason(null);
      setInitialPage(null);
    }
  }, [query.get("s")]);

  return {
    loadingSeasons: loading,
    seasonOptions,
    selectedSeason,
    initialPage,
    setSeasons,
    scrollToLastWeek: true,
  };
};
