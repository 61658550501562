import React, { useState } from "react";

import { Modal } from "@material-ui/core";
import { Formik, Form } from "formik";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAlert from "@material-ui/lab/Alert";
import ErrorBoundary from "./ErrorBoundary";
import Button from "./Button";
import { makeStyles } from "@material-ui/core/styles";
import { inviteAthlete } from "../queries/queries";
import { useMutation } from "react-query";
import auth from "../auth";

const GeneralModal = ({ modalOpen, setModalOpen, title, children }) => {
  const mobile = window.innerWidth < 700;

  const modalStyle = {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  };

  const useStyles = makeStyles((theme) => ({
    paper: {
      position: "absolute",
      width: mobile ? window.innerWidth - 50 : 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

  const classes = useStyles();
  return (
    <ErrorBoundary>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <p id="simple-modal-description" style={{ fontSize: 16, fontWeight: "bold" }}>
            {title && title}
          </p>
          {React.cloneElement(children, { modalOpen, setModalOpen })}
        </div>
      </Modal>
    </ErrorBoundary>
  );
};

export default GeneralModal;
