import React from "react";
import ReactDOM from "react-dom";

import App from "../App";
import ErrorBoundary from "../components/ErrorBoundary";
var el = document.createElement("div");
el.style.cssText = "height: 100%";

document.addEventListener("DOMContentLoaded", () => {
  ReactDOM.render(
    <ErrorBoundary>
      <App />
    </ErrorBoundary>,
    document.body.appendChild(el)
  );
});
