import React, { useState } from "react";

import {
  TextField,
  Modal,
  Snackbar,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Radio,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAlert from "@material-ui/lab/Alert";
import ErrorBoundary from "./ErrorBoundary";
import Button from "./Button";
import { makeStyles } from "@material-ui/core/styles";
import { inviteAthlete } from "../queries/queries";
import { useMutation } from "react-query";
import auth from "../auth";

export default function AddAthlete({ modalOpen, setModalOpen, mobile, setInviteSuccess }) {
  const [value, setValue] = React.useState("Urheilija");
  const [success, setSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Jotain meni vikaan");
  const currentUser = auth.currentUser();

  const [inviteAthleteWithEmail, { isLoading, error: inviteErrror }] = useMutation(inviteAthlete, {
    onSuccess(data) {
      ////consume.log("data", data);
      setModalOpen(false);
      setInviteSuccess(true);
    },
    onError(error) {
      let errorObj = JSON.parse(error.message);

      setErrorMessage(errorObj.message);
      setSubmitError(true);
    },
  });

  const handleSubmit = ({ email }) => {
    let payload = { email: email, role: value === "Urheilija" ? "athlete" : "coach" };
    inviteAthleteWithEmail(payload);
  };

  const handle = (value) => {
    ////consume.log(value);
    setValue(value);
  };

  const useStyles = makeStyles((theme) => ({
    paper: {
      position: "absolute",
      width: mobile ? window.innerWidth - 50 : 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

  const classes = useStyles();

  return (
    <ErrorBoundary>
      <Formik initialValues={{ email: "" }} onSubmit={handleSubmit}>
        {({ values, errors, handleChange, isSubmitting }) => (
          <Form className={classes.form} noValidate>
            {currentUser && currentUser.role === "admin" && (
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="role"
                  name="role"
                  value={value}
                  onChange={(e) => handle(e.target.value)}
                >
                  <FormControlLabel value="Urheilija" control={<Radio />} label="Urheilija" />
                  <FormControlLabel value="Valmentaja" control={<Radio />} label="Valmentaja" />
                </RadioGroup>
              </FormControl>
            )}

            <TextField
              error={errors.email}
              helperText={errors.email}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Sähköposti"
              name="email"
              autoFocus
              value={values.email}
              onChange={handleChange}
            />

            {!isLoading ? (
              <Button
                type="submit"
                fullWidth
                disabled={!isLoading}
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => handleSubmit(values)}
              >
                Lähetä
              </Button>
            ) : (
              <div style={{ marginTop: 10 }}>
                <CircularProgress size={25} />
              </div>
            )}
          </Form>
        )}
      </Formik>
    </ErrorBoundary>
  );
}
